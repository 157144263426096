import React, { useState, useEffect } from "react";
import { Route, Routes, NavLink, useNavigate } from "react-router-dom";
import DashBoard from "../../pages/DashBoard";
import User from "../../pages/User";
import Company from "../../pages/Company";
import CompanyCreation from "../../pages/CompanyCreation";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { LuDot } from "react-icons/lu";
import { FaSquarePlus } from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import { AiFillGolden } from "react-icons/ai";
import { RiDeviceRecoverLine } from "react-icons/ri";
import { FaPersonCircleQuestion } from "react-icons/fa6";
import { LuMousePointer2 } from "react-icons/lu";
import { Button, Collapse, Modal } from "react-bootstrap";
import { ClickButton } from "../ClickButton";
import "bootstrap/dist/css/bootstrap.min.css";
import JewelPawning from "../../pages/JewelPawning";
import JewelRecovery from "../../pages/JewelRecovery";
import JewelEstimate from "../../pages/JewelEstimate";
import {
  MdOutlineDashboard,
  MdOutlinePerson,
  MdOutlineStorefront,
} from "react-icons/md";
import Group from "../../pages/Group";
import Customer from "../../pages/Customer";
import JewelPawningCreation from "../../pages/JewelPawningCreation";
import UserCreation from "../../pages/UserCreation";
import JewelRecoveryCreation from "../../pages/JewelRecoveryCreation ";
import JewelEstimateCreation from "../../pages/JewelEstimateCreation";
import { BsBoxArrowRight } from "react-icons/bs";
import GroupCreation from "../../pages/GroupCreation";
import Interest from "../../pages/Interest";
import InterestCreation from "../../pages/InterestCreation";
import PawnReport from "../../pages/PawnReport";
import OverallReport from "../../pages/OverallReport";
import RecoveryReport from "../../pages/RecoveryReport";
import InterstReport from "../../pages/InterstReport";
import JewelPawnG from "../../pages/JewelPawnG";
import JewelPawnGCreation from "../../pages/JewelPawnGCreation";
import CustomerDetails from "../../pages/CustomerDetails";
import PawnGReport from "../../pages/PawnGReport";
const SideBar = ({ onLogout }) => {
  const [user, setUser] = useState([]);
  console.log(user);
  const [openMenu, setOpenMenu] = useState(
    JSON.parse(localStorage.getItem("openMenu")) || {}
  );
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const handleMenuClick = (menuIndex) => {
    setOpenMenu((prevOpenMenu) => {
      const newOpenMenu = {};
      for (let key in prevOpenMenu) {
        newOpenMenu[key] = false;
      }
      newOpenMenu[menuIndex] = !prevOpenMenu[menuIndex];
      return newOpenMenu;
    });
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    localStorage.setItem("openMenu", JSON.stringify(openMenu));
  }, [openMenu]);
  const [open, setOpen] = useState(null);
  const [isDeactive, SetDeactive] = useState(false);

  const navigate = useNavigate();
  const handleLogout = () => {
    onLogout();
    localStorage.removeItem("user");

    navigate("/login");
  };
  const toggle = () => {
    SetDeactive(!isDeactive ? "remove" : "");
  };
  const handleShowLogoutModal = () => setShowLogoutModal(true);
  const handleCloseLogoutModal = () => setShowLogoutModal(false);
  return (
    <>
      <div
        className={` ${isDeactive ? "wrap-remove" : ""}`}
        id="sidebar-wrapper"
      >
        <div className="list-group regular">
          <ul>
            <li>
              <div className="user-logo mx-auto">
                <img
                  src={require("../sidebar/images/logo.png")}
                  className="img-fluid logo"
                  alt=""
                />
              </div>
            </li>
            <li>
              <NavLink to="/console/dashboard" className="nav-link ">
                <span className="list-icon">
                  <MdOutlineDashboard />
                </span>
                <span class="list-text">டாஷ்போர்டு</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/console/user"
                className="nav-link"
                onClick={window.innerWidth <= 768 ? toggle : null}
              >
                <span className="list-icon">
                  <MdOutlinePerson />
                </span>
                <span class="list-text">பயனர் & அணுகல்</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/console/company"
                className="nav-link"
                activeClassName="active-link"
                onClick={window.innerWidth <= 768 ? toggle : null}
              >
                <span className="list-icon">
                  <MdOutlineStorefront />
                </span>
                <span class="list-text">நிறுவனம்</span>
              </NavLink>
            </li>
            <li>
              <div
                className="sub-menu nav-link"
                onClick={() => handleMenuClick(0)}
              >
                <span className="list-icon">
                  <FaSquarePlus />
                </span>
                <span class="list-text"> Master</span>
                <span
                  className={`list-icon arrow ${openMenu[0] ? "rotate" : ""}`}
                >
                  <MdOutlineKeyboardArrowRight />
                </span>
              </div>
              <Collapse in={openMenu[0]}>
                <ul className="submenu-list">
                  <li>
                    <NavLink
                      to="/console/master/group"
                      className="nav-link "
                      onClick={window.innerWidth <= 768 ? toggle : null}
                    >
                      <span className="list-icon">
                        <LuDot />
                      </span>
                      <span class="list-text">Group</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/console/master/customer"
                      className="nav-link "
                      onClick={window.innerWidth <= 768 ? toggle : null}
                    >
                      <span className="list-icon">
                        <LuDot />
                      </span>
                      <span class="list-text">Customer</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <div
                className="sub-menu nav-link"
                onClick={() => handleMenuClick(1)}
              >
                <span className="list-icon">
                  <AiFillGolden />
                </span>
                <span class="list-text">அடகு</span>
                <span
                  className={`list-icon arrow ${openMenu[1] ? "rotate" : ""}`}
                >
                  <MdOutlineKeyboardArrowRight />
                </span>
              </div>
              <Collapse in={openMenu[1]}>
                <ul className="submenu-list">
                  <li>
                    <NavLink
                      to="/console/pawn/jewelpawning"
                      className="nav-link"
                      onClick={window.innerWidth <= 768 ? toggle : null}
                    >
                      <span className="list-icon">
                        <AiFillGolden />
                      </span>
                      <span class="list-text">நகை அடகு </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/console/pawn/jewelpawng"
                      className="nav-link"
                      onClick={window.innerWidth <= 768 ? toggle : null}
                    >
                      <span className="list-icon">
                        <AiFillGolden />
                      </span>
                      <span class="list-text">நகை அடகு - G </span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <NavLink
                to="/console/interest"
                className="nav-link"
                activeClassName="active-link"
                onClick={window.innerWidth <= 768 ? toggle : null}
              >
                <span className="list-icon">
                  <LuMousePointer2 />
                </span>
                <span class="list-text">வட்டி </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/console/master/jewelrecovery"
                className="nav-link"
                onClick={window.innerWidth <= 768 ? toggle : null}
              >
                <span className="list-icon">
                  <RiDeviceRecoverLine />
                </span>
                <span class="list-text">நகை மீட்பு</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/console/master/jewelestimate"
                className="nav-link "
                onClick={window.innerWidth <= 768 ? toggle : null}
              >
                <span className="list-icon">
                  <FaPersonCircleQuestion />
                </span>
                <span class="list-text">எஸ்டிமேட்</span>
              </NavLink>
            </li>
            <li>
              <div
                className="sub-menu nav-link"
                onClick={() => handleMenuClick(2)}
              >
                <span className="list-icon">
                  <FaSquarePlus />
                </span>
                <span class="list-text"> அறிக்கைகள்</span>
                <span
                  className={`list-icon arrow ${openMenu[2] ? "rotate" : ""}`}
                >
                  <MdOutlineKeyboardArrowRight />
                </span>
              </div>
              <Collapse in={openMenu[2]}>
                <ul className="submenu-list">
                  <li>
                    <NavLink
                      to="/console/report/pawn"
                      className="nav-link"
                      onClick={window.innerWidth <= 768 ? toggle : null}
                    >
                      <span className="list-icon">
                        <LuDot />
                      </span>
                      <span class="list-text"> Pawn</span>
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/console/report/pawng" className="nav-link" onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">  Pawn - G</span>
                    </NavLink>
                  </li> */}
                </ul>
              </Collapse>
            </li>
          </ul>
        </div>
      </div>
      {/* main-content start */}
      <div className="navbar navbar-expand  px-lg-4 header">
        <div className="d-lg-block d-none">
          <Button className="menu-toggle" onClick={toggle} id="menu-toggle">
            <span class="navbar-toggler-icon"></span>
          </Button>
        </div>
        <div className="d-block d-lg-none ms-auto">
          <Button className="menu-toggle" onClick={toggle} id="menu-toggle">
            <span class="navbar-toggler-icon"></span>
          </Button>
        </div>
        <div class="collapse navbar-collapse" id="navbar-list">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item mx-3">
              <span className="mx-1">
                <FiUser />
              </span>
              <span className="mx-1">
                {" "}
                {user !== "" || user !== null ? user.user_name : "Aravind"}
              </span>
            </li>
            <li class="nav-item mx-3">
              <button onClick={handleShowLogoutModal}>
                <span className="list-icon">
                  <BsBoxArrowRight />
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div
        id="page-content-wrapper"
        className={`${isDeactive ? "page-remove" : ""}`}
      >
        <div className="content-bg">
          <div className="px-4 py-4">
            <div>
              <Routes>
                <Route
                  path="/console/dashboard"
                  element={<DashBoard />}
                ></Route>
                <Route path="/console/user" element={<User />}></Route>
                <Route
                  path="/console/user/create"
                  element={<UserCreation />}
                ></Route>
                <Route path="/console/company" element={<Company />}></Route>
                <Route
                  path="/console/company/create"
                  element={<CompanyCreation />}
                ></Route>
                <Route path="/console/interest" element={<Interest />}></Route>
                <Route
                  path="/console/interest/create"
                  element={<InterestCreation />}
                ></Route>
                <Route
                  path="/console/pawn/jewelpawning"
                  element={<JewelPawning />}
                ></Route>
                <Route
                  path="/console/pawn/jewelpawning/create"
                  element={<JewelPawningCreation />}
                ></Route>
                <Route
                  path="/console/pawn/jewelpawng"
                  element={<JewelPawnG />}
                ></Route>
                <Route
                  path="/console/pawn/jewelpawng/create"
                  element={<JewelPawnGCreation />}
                ></Route>
                <Route
                  path="/console/master/jewelrecovery"
                  element={<JewelRecovery />}
                ></Route>
                <Route
                  path="/console/master/jewelrecovery/create"
                  element={<JewelRecoveryCreation />}
                ></Route>
                <Route
                  path="/console/master/jewelestimate"
                  element={<JewelEstimate />}
                ></Route>
                <Route
                  path="/console/master/jewelestimate/create"
                  element={<JewelEstimateCreation />}
                ></Route>
                <Route path="/console/master/group" element={<Group />}></Route>
                <Route
                  path="/console/master/group/create"
                  element={<GroupCreation />}
                ></Route>
                <Route
                  path="/console/master/customer"
                  element={<Customer />}
                ></Route>
                <Route
                  path="/console/master/customerdetails"
                  element={<CustomerDetails />}
                ></Route>
                <Route
                  path="/console/report/overall"
                  element={<OverallReport />}
                ></Route>
                <Route
                  path="/console/report/pawn"
                  element={<PawnReport />}
                ></Route>
                <Route
                  path="/console/report/pawng"
                  element={<PawnGReport />}
                ></Route>
                <Route
                  path="/console/report/recovery"
                  element={<RecoveryReport />}
                ></Route>
                <Route
                  path="/console/report/interst"
                  element={<InterstReport />}
                ></Route>
              </Routes>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showLogoutModal} onHide={handleCloseLogoutModal}>
        <Modal.Header closeButton>
          <Modal.Title>Logout Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <ClickButton
            label={<>No</>}
            onClick={handleCloseLogoutModal}
          ></ClickButton>
          <ClickButton label={<>Yes</>} onClick={handleLogout}></ClickButton>
        </Modal.Footer>
      </Modal>
      {/* main-content-end */}
    </>
  );
};

export default SideBar;
